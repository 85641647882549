<template>
  <a-upload
    :headers="headers"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="upLoadObj.action"
    :before-upload="beforeUpload"
    @change="handleImgChange"
  >
    <slot :data="{ value, imgLoading }">
      <img v-if="value" :src="value" alt="avatar" class="img-style">
      <div v-else>
        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </slot>
  </a-upload>
</template>

<script>
/**
* @author  dengqingbin
* @description 图片上传组件
* 1.用于[用户管理编辑页面]：上传营业执照
*/
import {
  beforeUpload,
  getBaseCallback64,
  headers,
  upLoadObj
} from '@/utils/utils'
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      headers,
      beforeUpload,
      upLoadObj,
      imgLoading: false
    }
  },
  methods: {
    handleImgChange(info) {
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        // 1.回调函数可以带上 imageUrl 本地转换的路径
        getBaseCallback64(info.file.originFileObj, () => {
          this.$emit('changeImg', info.file.response)
          this.imgLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.img-style {
  width: 200px;
}
</style>
