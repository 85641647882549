<template>
  <div style="min-height: 100vh">
    <wloading :show="showLoading" />
    <heard />

    <!-- 编辑页 -->
    <div class="main">
      <div class="mian-container">
        <button class="return_btn" @click="$router.go(-1)">
          <span>&lt;&nbsp;</span>
          <span>返回</span>
        </button>
        <div class="main-title">
          申请创建爱心版店铺
        </div>
        <div class="main-content">
          <a-form-model
            ref="formTable"
            layout="horizontal"
            hide-required-mark
            :colon="false"
            label-align="left"
            class="form-style"
            v-bind="layout"
            :model="form"
            :rules="rules"
          >
            <a-form-model-item prop="name">
              <template slot="label">
                <div>
                  <div class="label-text">
                    请输入申请人姓名
                  </div>
                  <div class="label-subtext">
                    请输入真实的姓名以通过申请
                  </div>
                </div>
              </template>
              <a-input
                v-model="form.name"
                class="input-style"
                placeholder="请输入您的名字"
              />
            </a-form-model-item>

            <a-form-model-item prop="phone">
              <template slot="label">
                <div>
                  <div class="label-text">
                    请输入手机号码
                  </div>
                  <div class="label-subtext">
                    请输入可以联系到您的手机号码和配合认身份证
                  </div>
                </div>
              </template>
              <a-input
                v-model="form.phone"
                class="input-style"
                placeholder="请输入您的手机号码"
              />
            </a-form-model-item>

            <div class="label-text text-position">
              请上传您的证件
            </div>
            <div class="label-subtext text-position">
              请上传您的居民身份证和符合申请条件的证件如:
              <br>
              学生证/退伍军人证/残疾证进行身份认证
            </div>
            <a-form-model-item prop="idCardTypeId">
              <template slot="label">
                <div>
                  <div class="label-subtext">
                    身份证上传
                  </div>
                </div>
              </template>
              <div class="card-container">
                <div class="type-select">
                  <a-select v-model="form.idCardTypeId" style="width: 200px" placeholder="请选择" allow-clear>
                    <a-select-option v-for="(item, index) in idcardtypeList" :key="index" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <a-input v-model="form.idCard" class="mar-l" placeholder="请输入你的身份证号码" />
                </div>
                <div class="type-select">
                  <Uploadimg v-model="form.idCardPositiveURL" @changeImg="getIdCardPositive">
                    <div slot-scope="{data}">
                      <img v-if="data.value" :src="data.value" class="img-solt" @click="seePic(data.value)">
                      <img v-else class="img-solt" src="/images/shop/idCardPositive.png" alt="">
                    </div>
                  </Uploadimg>
                  <Uploadimg v-model="form.idCardNegativeURL" @changeImg="getIdCardNegative">
                    <div slot-scope="{data}">
                      <img v-if="data.value" :src="data.value" class="img-solt" @click="seePic(data.value)">
                      <img v-else class="img-solt" src="/images/shop/idCardNegative.png" alt="">
                    </div>
                  </Uploadimg>
                </div>
              </div>
            </a-form-model-item>

            <!-- 其他证件 -->
            <a-form-model-item prop="certificateTypeId">
              <template slot="label">
                <div>
                  <div class="label-subtext">
                    学生证/退伍军人证/残疾证照片上传
                  </div>
                </div>
              </template>
              <div class="card-container">
                <div class="type-select">
                  <a-select v-model="form.certificateTypeId" style="width: 200px" placeholder="请选择" allow-clear>
                    <a-select-option v-for="(item, index) in certificatetypeList" :key="index" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <a-input v-model="form.certificatesCode" class="mar-l" placeholder="请输入你的证件编码" />
                </div>
                <div class="type-select">
                  <Uploadimg v-model="form.certificatesPositiveURL" @changeImg="getCertificatesPositive">
                    <div slot-scope="{data}">
                      <img v-if="data.value" :src="data.value" class="img-solt" @click="seePic(data.value)">
                      <img v-else class="img-solt" src="/images/shop/front.png" alt="">
                    </div>
                  </Uploadimg>
                  <Uploadimg v-model="form.certificatesNegativeURL" @changeImg="getCertificatesNegative">
                    <div slot-scope="{data}">
                      <img v-if="data.value" :src="data.value" class="img-solt" @click="seePic(data.value)">
                      <img v-else class="img-solt" src="/images/shop/reverseside.png" alt="">
                    </div>
                  </Uploadimg>
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <a-button
        type="primary"
        class="btn-style"
        @click="finish()"
      >
        确认上传
      </a-button>

      <a-modal v-model="visible" :footer="null" width="900px" title="查看图片">
        <div class="visibleImg">
          <img style="width: 100%;" :src="visibleImg">
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { isvalidPhone } from '@/utils/validate'
import heard from '../components/heard.vue'
import wloading from '../components/w-loading.vue'
import { addComboapply, getIdcardtype, getCertificatetype } from '@/api/register'
import Uploadimg from '@/components/UploadImg/UploadImg'

export default {
  components: {
    heard,
    wloading,
    Uploadimg
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入您的手机号码'))
      } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }

    const validIdCard = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择身份证类型'))
      } else if (!this.form.idCard) {
        callback(new Error('请输入身份证号'))
      } else if (!this.form.idCardPositive) {
        callback(new Error('请上传身份证头像面'))
      } else if (!this.form.idCardNegative) {
        callback(new Error('请上传身份证国徽面'))
      } else {
        callback()
      }
    }

    const validCertificate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入选择证件类型'))
      } else if (!this.form.certificatesCode) {
        callback(new Error('请输入您的证件号码'))
      } else if (!this.form.certificatesPositive) {
        callback(new Error('请上传证件展开正面'))
      } else if (!this.form.certificatesNegative) {
        callback(new Error('请上传证件展开内面'))
      } else {
        callback()
      }
    }

    return {
      visible: false, // 查看图片
      visibleImg: '/images/bg7.png',
      idcardtypeList: [], // 身份证类型
      certificatetypeList: [], // 证件类型

      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
      },
      showLoading: false, // 加载等待
      rules: {
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        phone: [{ required: true, trigger: 'blur', validator: validPhone }],
        certificateTypeId: [
          {
            required: true,
            validator: validCertificate,
            trigger: 'blur'
          }
        ],

        idCardTypeId: [
          { required: true, validator: validIdCard, trigger: 'blur' }
        ]
      },
      form: {
        idCardTypeId: -1, // 身份证类型
        certificateTypeId: -1, // 证件类型
        name: '',
        phone: '',
        certificates: '', // 学生证,退伍军人证,残疾证
        certificatesPositive: '', // 证件正面
        certificatesNegative: '', // 证件反面
        certificatesPositiveURL: '', // 证件正面URL
        certificatesNegativeURL: '', // 证件反面URL
        idCardNegative: '', // 身份证反面
        idCardNegativeURL: '', // 身份证反面URL
        idCardPositive: '', // 身份证正面
        idCardPositiveURL: '', // 身份证正面URL
        certificatesCode: '', // 证件编码
        idCard: '' // 身份证号
      }
    }
  },
  created() {
    // 获取身份证类型
    getIdcardtype().then(res => {
      if (res.code === 0) {
        this.idcardtypeList = res.data
        this.form.idCardTypeId = res.data.length > 0 ? res.data[0].id : ''
      }
    })

    /** 获取证件类型 */
    getCertificatetype().then(res => {
      if (res.code === 0) {
        this.certificatetypeList = res.data
        this.form.certificateTypeId = res.data.length > 0 ? res.data[0].id : ''
      }
    })
  },

  mounted() {
    const m = function(e) {
      e.preventDefault()
    }
    document.body.style.overflow = '' // 出现滚动条
    document.removeEventListener('touchmove', m, { passive: true })
  },
  methods: {

    /**
     * @title 打开弹窗查看图片
     * @param visibleImg
     */
    seePic(visibleImg) {
      return
      // this.visibleImg = visibleImg
      // this.visible = true
    },

    /**
     * @title 图片上传事件：身份证正面
     */
    getIdCardPositive(imgUrl) {
      if (!imgUrl) return
      this.form.idCardPositiveURL = 'data:image/png;base64,' + imgUrl
      this.form.idCardPositive = imgUrl
    },

    /**
     * @title 图片上传事件：身份证反面
     */
    getIdCardNegative(imgUrl) {
      if (!imgUrl) return
      this.form.idCardNegativeURL = 'data:image/png;base64,' + imgUrl
      this.form.idCardNegative = imgUrl
    },

    /**
     * @title 图片上传事件：证件正面
     */
    getCertificatesPositive(imgUrl) {
      if (!imgUrl) return
      this.form.certificatesPositiveURL = 'data:image/png;base64,' + imgUrl
      this.form.certificatesPositive = imgUrl
    },

    /**
     * @title 图片上传事件：证件反面
     */
    getCertificatesNegative(imgUrl) {
      if (!imgUrl) return
      this.form.certificatesNegativeURL = 'data:image/png;base64,' + imgUrl
      this.form.certificatesNegative = imgUrl
    },

    finish() {
      const that = this
      this.$refs['formTable'].validate((valid) => {
        if (valid) {
          that.showLoading = true

          // 结构对象
          let comboapplyObj = {}
          const {
            idCardTypeId,
            certificateTypeId, // 证件类型
            name,
            phone,
            certificates, // 学生证,退伍军人证,残疾证
            certificatesPositive, // 证件正面
            certificatesNegative, // 证件反面
            idCardNegative, // 身份证反面
            idCardPositive, // 身份证正面
            certificatesCode, // 证件编码
            idCard // 身份证号
          } = that.form

          comboapplyObj = {
            idCardTypeId, // 身份证类型
            certificateTypeId, // 证件类型
            name, // 名称
            phone, // 电话
            certificates, // 学生证,退伍军人证,残疾证
            certificatesPositive, // 证件正面
            certificatesNegative, // 证件反面
            idCardNegative, // 身份证反面
            idCardPositive, // 身份证正面
            certificatesCode, // 证件编码
            idCard // 身份证号
          }

          addComboapply(comboapplyObj)
            .then((res) => {
              if (res.code === 0) {
                Message.success(res.data)
                that.showLoading = false
                that.$router.push({ name: 'shopManagement' })
              } else {
                Message.error(res.msg)
                that.showLoading = false
              }
            })
            .catch((res) => {
              that.showLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.return_btn{
  width: 100%;
  text-align: left;
  margin-top: 10px;
  color: rgb(122, 132, 153);
  padding: 0;
  height: 22px;
  font-size: 12px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}
// 文本居左
.text-position {
  text-align: left;
}
.text-position + .text-position {
  margin-bottom: 16px;
}

// 主容器
.main {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;

  // 容器
  .main-container{
    width: 1262px;
  }
  &-title {
    padding: 20px 0;
    width: 100%;
    text-align: left;
    font-size: 21px;
    color: #363636;
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: 1262px;
    text-align: left;
    border-radius: 12px;
    background-color: #fff;
  }
}
// 表单调整
.form-style {
  margin: 94px 110px 0 110px;
}

// 输入框长度
.input-style {
  width: 100%; // 1262
  // width: 550px;
}

// 文本
.label-text {
  font-weight: bold;
  max-width: 325px;
  font-size: 27px;
  color: #0364ff;
}

// 富文本
.label-subtext {
  max-width: 325px;
  font-size: 12px;
  color: #5193fe;
}

// 按钮
.btn-style {
  margin-top: 90px;
  margin-bottom: 72px;
  width: 267px;
  height: 55px;
}

// 卡片自定义
.card-container{
  .mar-l{
    margin-left: 8px;
  }
  .type-select{
    display: flex;
    justify-content: space-between;
  }

  .type-select + .type-select {
    margin-top: 16px;
  }

  .img-solt{
    width: 269px;
    height: 161px;
    object-fit: fill;
  }
}

.visibleImg{
  display: flex;
  flex-direction: column;
  align-items: center;
}

// 去除ant的内边距
::v-deep .ant-upload.ant-upload-select-picture-card > .ant-upload {
   padding: unset;
}

::v-deep .ant-form-item-label{
  line-height: unset;
}
</style>
